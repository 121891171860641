<template>
  <button class="button" :class="[variant, color, size]" :disabled="disabled"
    ref="clickBtn" v-on:click="animateRipple">
    <slot></slot>
    <transition-group>
      <span class="button-effect" :ref="'button-effect' + i"
        :key="'button-effect' + i" v-for="(val, i) in activeRipples"
        :style="{ top: val.y + 'px', left: val.x + 'px' }"
        v-on:animationend="rippleEnd(i)">
      </span>
    </transition-group>
  </button>
</template>

<script>
export default {
  name: 'HccButton',
  props: {
    type: {
      type: String,
      default: 'button'
    },
    variant: {
      type: String,
      default: 'fill'
    },
    color: {
      type: String,
      default: 'sellia-secondary'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'md'
    }
  },
  data() {
    return {
      ripples: []
    }
  },
  computed: {
    activeRipples() {
      return this.ripples.filter((r) => r.show)
    }
  },
  methods: {
    animateRipple(e) {
      const el = this.$refs.clickBtn
      const pos = el.getBoundingClientRect()
      this.ripples.push({
        x: e.clientX - pos.left,
        y: e.clientY - pos.top,
        show: true
      })
      this.$emit('click')
    },
    rippleEnd(i) {
      this.ripples[i].show = false
    }
  }
}
</script>

<style scoped lang="scss">
@import "~styles/components/buttons.scss"
</style>
